<template>
  <!-- 店铺列表
        除了超级终端外其他店铺使用时只显示自己店铺的一条信息
        超级终端可查看所有店铺的信息，可以进行编辑
   -->
  <el-card class="box-card">
    <el-page-header
      class="product-header"
      @back="goBack"
      content="新建授信店铺"
    ></el-page-header>
    <el-form class="select-style">
      <el-form-item label="查找店铺:">
        <el-select
          v-model="storename"
          filterable
          remote
          reserve-keyword
          placeholder="请输入操作的店铺名称"
          :remote-method="remoteMethodsTorename"
          :loading="loading"
          @select="handleSelect"
          style="width: 25%; margin-right: 2%"
        >
          <el-option
            v-for="item in storeOptions"
            :key="item.storeFlag"
            :label="`${item.title}`"
            :value="item.storeFlag"
          >
          </el-option>
        </el-select>
        <el-button type="success" @click="selectButton">确定</el-button>
      </el-form-item>
      <el-form-item label="授信店铺名称">
        <el-input v-model="storename.title" disabled class="_input"></el-input>
      </el-form-item>
      <el-form-item label="授信店铺标识">
        <el-input
          v-model="storename.storeFlag"
          disabled
          class="_input"
        ></el-input>
      </el-form-item>
      <el-form-item label="授信店铺联系方式">
        <el-input
          v-model="storename.phonenum"
          disabled
          class="_input"
        ></el-input>
      </el-form-item>
      <el-form-item label="授信金额">
        <el-input v-model="storename.money" style="width: 200px"></el-input>
      </el-form-item>
      <el-form-item label="备注">
        <el-input
          type="textarea"
          :rows="2"
          v-model="storename.remark"
          class="_input"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="extensionSuccess">确定</el-button>
        <el-button type="warning" @click="reset">重置</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>
import axios from "axios";
import secret from "@/utils/secret.js";
import moment from "moment";
export default {
  inject: ["reload"],
  data: function () {
    return {
      cut: 0,
      selectStyle: "1",
      storename: "",
      loading: false,
      storeOptions: [],
      storeInfo: "",
      storeKey: "",
    };
  },
  mounted() {
    this.storeKey = secret.Decrypt(localStorage.getItem("user_key"));
  },
  methods: {
    goBack() {
      this.$router.push("/homepage");
    },
    remoteMethodsTorename(query) {
      if (query !== "") {
        this.loading = true;
        setTimeout(() => {
          const title = query;
          let storeKey = this.storeKey;
          this.storeOptions = axios
            .post(this.GLOBAL.api + "/store", { title, storeKey })
            .then((item) => {
              if (item.data.length === 0) {
                this.$message({
                  type: "warning",
                  message: "没有搜索到该店铺",
                });
              }
              return (this.storeOptions = item.data);
            });
          this.loading = false;
        }, 200);
      } else {
        this.storeOptions = [];
      }
    },
    handleSelect(item) {
    },
    selectButton() {
      if (this.storeOptions.length === 0) {
        this.$message({
          type: "error",
          message: "请输入店铺名称",
        });
      } else {
        this.storeOptions.forEach((element) => {
          if (element.storeFlag === this.storename) {
            return (this.storename = element);
          }
        });
      }
    },
    extensionSuccess() {
      this.storename.createdTime = moment().format("YYYY-MM-DD HH:mm:ss");
      let val = this.storename;
      if (val === "" || !val.money) {
        this.$message({
          type: "error",
          message: "请保持信息填写完整！",
        });
      } else if (this.storeKey === val.storeFlag) {
        this.$message({
          type: "error",
          message: "不允许给自己店铺创建授信！",
        });
      } else {
        let storeKey = this.storeKey;
        let credit = true;
        axios
          .post(this.GLOBAL.api + "/store", { storeKey, credit, val })
          .then((res) => {
            if (res.data.statusCode === 502) {
              this.$message({
                type: "error",
                message: "该店铺已进行过授信，请直接去修改！",
              });
            } else if (res.status !== 200 && res.data.statusCode !== 200) {
              this.$message({
                type: "error",
                message: "创建时出现未知错误，请重试！",
              });
            } else {
              this.$message({
                type: "success",
                message: "新建授信店铺成功！",
              });
            }
          });
      }
    },
    reset() {
      this.storename = "";
    },
  },
  watch: {},
};
</script>
<style scoped>
.product-header {
  height: 40px;
  background-color: #f9f9f9;
  line-height: 40px;
  padding-left: 1%;
}
body {
  padding: 10px 20px;
}
.select-style {
  margin: 20px 0 10px 20px;
}
._input {
  width: 400px;
}
</style>